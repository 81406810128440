import React from 'react';
import { Link } from 'gatsby';

import * as styles from './Footer.module.scss';

import HorizontalRule from '../HorizontalRule/HorizontalRule';

import logoSmallImg from '../../images/logo--small.svg';
import IconMapSearch from '../Icons/IconMapSearch';
import IconPhone from '../Icons/IconPhone';
import IconMail from '../Icons/IconMail';

const Footer = (): JSX.Element => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={styles.Footer}>
            <div className="contain py-5 space-y-5">
                <div className="flex items-center justify-between">
                    <img src={logoSmallImg} alt="Small logo" width={32} height={29} />
                    <nav className="space-x-2">
                        <Link to="/" className={`${styles.FooterLink} text-smallcaps`}>Home</Link>
                        <Link to="/design" className={`${styles.FooterLink} text-smallcaps`}>Design</Link>
                        <Link to="/build" className={`${styles.FooterLink} text-smallcaps`}>Build</Link>
                        <Link to="/contact" className={`${styles.FooterLink} text-smallcaps`}>Contact</Link>
                    </nav>
                </div>
                <HorizontalRule color="bronze-700" />
                <div className={styles.FooterContact}>
                    <div className="flex basis-auto gap-1">
                        <IconMapSearch size="24px" />
                        <address className="text-smallcaps">
                            30 Stanwick Drive<br />
                            Wyman's Brook<br />
                            Cheltenham<br />
                            GL51 9LG
                        </address>
                    </div>
                    <div className="flex basis-auto gap-1">
                        <IconPhone size="24px" />
                        <div>
                            <a href="tel:07791636845" className={`${styles.FooterLink} text-smallcaps`}>07791 636 845</a>
                        </div>
                    </div>
                    <div className="flex basis-auto gap-1 items-center">
                        <IconMail size="24px" />
                        <a href="mailto:office@constructsbuilding.co.uk" className={`${styles.FooterLink} text-smallcaps`}>office@constructsbuilding.co.uk</a>
                    </div>
                </div>
                <HorizontalRule color="bronze-700" />
                <div className="sm:text-center leading-tight">
                    <span className="text-smallcaps">&copy; {currentYear}, All rights reserved.</span><br />
                    <Link to="/privacy-policy" className={`${styles.FooterLink} text-smallcaps`}>Privacy policy</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
